import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { KidsQuery } from '../../../../graphql-types'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import KidsLead from './components/kids-lead/kids-lead'
import AnotherSection from '../../components/another-section/another-section'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'

type KidsProps = {}

const Kids: React.FC<KidsProps> = ({}) => {
  const data = useStaticQuery<KidsQuery>(query)
  const { lead, otherSections } = data.kidsPageInfo?.frontmatter ?? {}

  return (
    <>
      <SEO title="Kids" />

      <Layout>
        {!lead?.isEnabled ? null : <KidsLead {...lead} />}
        {otherSections?.map((anotherSection, i) =>
          !anotherSection?.isEnabled ? null : (
            <AnotherSection key={i} {...anotherSection} />
          )
        ) || null}
        <EmailRegistration />
      </Layout>
    </>
  )
}

export default Kids

const query = graphql`
  query Kids {
    kidsPageInfo: markdownRemark(fields: { slug: { eq: "/info/kids-page/" } }) {
      frontmatter {
        lead {
          isEnabled
          bg
          title
          description
        }
        otherSections {
          isEnabled
          bg
          align
          title
          description
          btnText
          btnUrl
        }
      }
    }
  }
`
